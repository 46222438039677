<template>
  <div class="columns" style="padding-bottom: 4rem;">
    <div class="column col-8 col-sm-12 col-offset-2 txt--center">
      <h1 class="team__member-headline">
        {{ $t("advisoryBoard.andreas_straarup.name") }}
      </h1>
      <!-- <p>{{ $t("team.markus_honkanen.position") }}</p> -->
    </div>
    <div class="column col-10 col-sm-12 col-offset-1 txt--center">
      <div v-html="$t('advisoryBoard.andreas_straarup.intro')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Straarup",
};
</script>
